import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 12px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 24px;
  max-width: 432px;
  text-align: center;
`;

export const Button = styled.a`
  display: block;
  padding: 16px 36px;
  border: 1px solid #ddd7d5;
  box-sizing: border-box;
  border-radius: 8px;
  text-decoration: none;
  color: currentColor;
  font-weight: 500;
  cursor: pointer;
  z-index: 1;
  background-color: grey;

  :hover {
    background-color: white;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 72px auto 47px;
  color: grey;
  z-index: 10;
`;
