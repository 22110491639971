import React from 'react';

import { Button, ErrorWrapper, InfoWrapper, Text, Title } from './styled';

interface ErrorProps {
  url: string;
  statusCode: number;
}

const AUTHORIZATION_CODE = 401;
const FORBIDDEN_CODE = 403;

export const Error = React.memo(({ statusCode, url }: ErrorProps) => {
  return (
    <>
      <ErrorWrapper>
        <InfoWrapper>{renderError()}</InfoWrapper>
      </ErrorWrapper>
    </>
  );

  function renderError() {
    if ([AUTHORIZATION_CODE, FORBIDDEN_CODE].includes(statusCode)) {
      const isAuth = statusCode !== AUTHORIZATION_CODE;

      return (
        <>
          <Title>Не авториозован</Title>
          <Text>${isAuth ? 'msg_403' : 'msg_401'}</Text>
          <Button onClick={open}>use phone number to login</Button>
        </>
      );
    }

    return (
      <>
        <Title>Error {statusCode}</Title>
        <Text>404</Text>
      </>
    );
  }
});
